<template>
  <div>
    <a-card title="销售开单">
      <div slot="extra" style="margin: -4px 0">
        <a-button type="primary" icon="left" ghost @click="$router.go(-1)">返回</a-button>
      </div>

      <a-spin :spinning="loading">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-row>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="number" label="销售单号">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="handle_time" label="制单日期">
                <a-date-picker v-model="form.handle_time" valueFormat="YYYY-MM-DD" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="client_name" label="客户">
                <ClientInput v-model="form.client_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="handler" label="制单人">
                <a-select v-model="form.handler" style="width: 100%">
                  <a-select-option v-for="item in handlerItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="delivery_date" label="交货日期">
                <a-date-picker v-model="form.delivery_date" valueFormat="YYYY-MM-DD" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-item prop="sales_files" label="附件">
                <SalesFileUpload v-model="form.sales_files" :initialItems="[]" />
              </a-form-item>
            </a-col>

            <!-- <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="company_order_number" label="公司单号">
                <a-input v-model="form.company_order_number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="product_model" label="产品型号">
                <a-input v-model="form.product_model" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="product_name" label="产品名称">
                <a-input v-model="form.product_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="inspection_date" label="验货日期">
                <a-date-picker v-model="form.inspection_date" valueFormat="YYYY-MM-DD" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="contract_number" label="合同号">
                <a-input v-model="form.contract_number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="client_model" label="客户型号">
                <a-input v-model="form.client_model" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="parameter" label="参数">
                <a-input v-model="form.parameter" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="sales_country" label="销售国家">
                <a-input v-model="form.sales_country" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="order_quantity" label="订单数量">
                <a-input-number v-model="form.order_quantity" :min="0" style="width: 100%" />
              </a-form-model-item>
            </a-col> -->
          </a-row>
        </a-form-model>

        <a-divider orientation="left">产品信息</a-divider>

        <div>
          <a-row gutter="16">
            <a-space>
              <a-button type="primary" @click="openMaterialModal">添加产品</a-button>
            </a-space>
          </a-row>
          <div style="margin-top: 16px">
            <a-table rowKey="id" size="small" :columns="columns" :data-source="goodsData" :pagination="false">
              <div slot="sales_quantity" slot-scope="value, item, index">
                <div v-if="item.isTotal">{{ value }}</div>
                <a-input-number v-else v-model="item.sales_quantity" :min="0" size="small"></a-input-number>
              </div>
              <div slot="sales_price" slot-scope="value, item, index">
                <a-input-number v-if="!item.isTotal" v-model="item.sales_price" :min="0" size="small"></a-input-number>
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group v-if="!item.isTotal" size="small">
                  <a-button type="default" @click="editMaterial(item)">编辑</a-button>
                  <a-button type="danger" @click="removeMaterial(item)">移除</a-button>
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>

        <div v-if="enableFinanceManage">
          <a-divider orientation="left">账单信息</a-divider>
          <a-row gutter="16">
            <a-col :span="4">
              <a-form-model-item prop="discount" label="整单折扣" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                <a-input-number v-model="form.discount" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="4">
              <a-form-model-item prop="other_amount" label="其他费用" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                <a-input-number v-model="form.other_amount" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="总计金额(元)" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                <a-input-number :value="totalAmount" :disabled="true" style="width: 100%" />
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row gutter="16">
            <a-col :span="4">
              <a-form-model-item label="结算账户" required :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                <a-select v-model="sales_account_item.account" style="width: 100%">
                  <a-select-option v-for="Account in accountsItems" :key="Account.id" :value="Account.id">
                    {{ Account.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="实收金额(元)" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                <a-input-number v-model="sales_account_item.collection_amount" style="width: 100%" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row gutter="16">
            <a-col :span="4">
              <a-form-model-item label="本单欠款(元)" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }">
                <a-input-number :value="amountOwed" :disabled="true" style="width: 100%" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="loading">创建</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <SalesInventorySelectModal v-model="materialsSelectModalVisible" @select="onSelectMaterial" />
    <GoodsUpdateModal v-model="updateModalVisible" :goods="targetMaterial" @update="updateMaterial" />
  </div>
</template>

<script>
import moment from "moment";
import { getSaleOrderNumber } from "@/api/data";
import { saleOrderCreate } from "@/api/sale";
import { userOption, warehousesOption, accountsOption } from "@/api/option";
import NP from "number-precision";

export default {
  components: {
    SalesInventorySelectModal: () => import("@/components/SalesInventorySelectModal"),
    SalesFileUpload: () => import("@/components/SalesFileUpload"),
    ClientInput: () => import("@/components/ClientInput"),
    GoodsUpdateModal: () => import("@/components/GoodsUpdateModal"),
  },
  data() {
    return {
      description: "新增",
      warehouseItems: [],
      handlerItems: [],
      accountsItems: [],
      materialsSelectModalVisible: false,
      loading: false,
      model: {},
      form: {},
      rules: {
        number: [
          { required: true, message: "请输入编号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        client_name: [{ required: true, message: "请输入客户", trigger: "change" }],
        handler: [{ required: true, message: "请选择制单人", trigger: "change" }],
        handle_time: [{ required: true, message: "请选择处理日期", trigger: "change" }],
        other_amount: [{ pattern: new RegExp(/^\d{0,14}(?:\.\d{0,2})?$/), message: "其他费用格式不正确", trigger: "change" }],
      },
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 60,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "编号",
          dataIndex: "number",
          key: "number",
        },
        {
          title: "规格",
          dataIndex: "spec",
          key: "spec",
        },
        {
          title: "单位",
          dataIndex: "unit",
          key: "unit",
        },
        {
          title: "销售数量",
          dataIndex: "sales_quantity",
          key: "sales_quantity",
          scopedSlots: { customRender: "sales_quantity" },
        },
        {
          title: "销售单价(元)",
          dataIndex: "sales_price",
          key: "sales_price",
          scopedSlots: { customRender: "sales_price" },
        },
        {
          title: "金额",
          dataIndex: "totalAmount",
          key: "totalAmount",
          customRender: (value, item) => {
            if (item.isTotal) return value;
            value = NP.times(item.sales_quantity, item.sales_price);
            return item.goods ? NP.round(value, 2) : "";
          },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      materialItems: [],
      columnsAccount: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "结算账户",
          dataIndex: "account",
          key: "account",
          width: 200,
          scopedSlots: { customRender: "account" },
        },
        {
          title: "收款金额",
          dataIndex: "collection_amount",
          key: "collection_amount",
          width: 200,
          scopedSlots: { customRender: "collection_amount" },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      sales_account_items: [],
      sales_account_item: {},
      clientLevel: "0",

      targetMaterial: null,
      updateModalVisible: false,
    };
  },
  computed: {
    enableFinanceManage() {
      return this.$store.state.user.enableFinanceManage;
    },
    defaultAccount() {
      return this.$store.state.user.defaultAccount;
    },
    totalAmount() {
      let totalAmount = 0;
      for (let item of this.materialItems) {
        let amount = NP.times(item.sales_quantity, item.sales_price);
        totalAmount = NP.plus(totalAmount, amount);
      }

      totalAmount = NP.times(totalAmount, this.form.discount || 100, 0.01);
      totalAmount = NP.plus(totalAmount, this.form.other_amount || 0);
      return totalAmount;
    },
    amountOwed() {
      return NP.minus(this.totalAmount, this.sales_account_item.collection_amount || 0);
    },
    goodsData() {
      // 统计合计
      let totalQuantity = 0,
        totalAmount = 0;
      for (let item of this.materialItems) {
        totalQuantity = NP.plus(totalQuantity, item.sales_quantity);
        let amount = NP.times(item.sales_quantity, item.sales_price);
        totalAmount = NP.plus(totalAmount, amount);
      }
      return [
        ...this.materialItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          sales_quantity: totalQuantity,
          totalAmount: `${totalAmount} * ${this.form.discount}% = ${NP.times(totalAmount, this.form.discount || 0, 0.01)}`,
        },
      ];
    },
    accountsData() {
      // 统计合计
      let totalAmount = 0;
      for (let item of this.sales_account_items) {
        totalAmount = NP.plus(totalAmount, item.collection_amount);
      }
      return [
        ...this.sales_account_items,
        {
          id: "-1",
          isTotal: true,
          collection_amount: totalAmount,
        },
      ];
    },
    userId() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    moment,
    initData() {
      this.resetForm();
      warehousesOption({ page_size: 999999, is_active: true }).then((data) => {
        this.warehouseItems = data.results;
      });
      userOption({ page_size: 999999, is_active: true }).then((data) => {
        this.handlerItems = data.results;
      });
      accountsOption({ page_size: 999999, is_active: true }).then((data) => {
        this.accountsItems = data.results;
      });

      this.form.handler = this.userId;
      this.form.handle_time = moment().format("YYYY-MM-DD");
    },
    handelAddAcount() {
      this.sales_account_items.push({
        id: this.sales_account_items.length + 1,
        account: "",
        collection_amount: 0,
      });
    },
    removeAccount(item) {
      this.sales_account_items = this.$functions.removeItem(this.sales_account_items, item);
    },
    changeAccount(value, item, idx) {
      let count = this.sales_account_items.filter((_item) => {
        return _item.account == value;
      });
      if (count.length > 1) {
        this.$message.warn("已添加过改结算账户!");
        this.sales_account_items[idx].account = "";
      }
    },
    openMaterialModal() {
      // if (!this.form.warehouse) {
      //   this.$message.warn("请先选择仓库！");
      //   return false;
      // }
      this.materialsSelectModalVisible = true;
    },
    onSelectMaterial(item) {
      let index = this.materialItems.findIndex((_item) => _item.id == item.id);

      if (index != -1) {
        this.$message.warn("产品已存在");
        return;
      }

      let sales_price = item.retail_price;
      if (this.clientLevel === "1") {
        sales_price = item.level_price1;
      } else if (this.clientLevel === "2") {
        sales_price = item.level_price2;
      } else if (this.clientLevel === "3") {
        sales_price = item.level_price3;
      }

      this.materialItems = this.$functions.insertItem(this.materialItems, {
        id: item.id,
        goods: item.id,
        number: item.number,
        name: item.name,
        spec: item.spec,
        unit: item.unit_name,
        sales_quantity: 1,
        sales_price: sales_price,
        retail_price: item.retail_price,
        level_price1: item.level_price1,
        level_price2: item.level_price2,
        level_price3: item.level_price3,
        totalAmount: 1,
      });

      this.materialItems = [...this.materialItems];
    },
    removeMaterial(item) {
      this.materialItems = this.$functions.removeItem(this.materialItems, item);
    },
    updateMaterial(item) {
      const index = this.materialItems.findIndex((salesGoodsItem) => salesGoodsItem.goods === item.id);
      if (index !== -1) {
        const updateItem = {
          ...this.materialItems[index],
          number: item.number,
          name: item.name,
          spec: item.spec,
          unit: item.unit_name,
        };
        this.materialItems.splice(index, 1, updateItem);
      }
    },
    editMaterial(item) {
      this.targetMaterial = item.goods;
      this.updateModalVisible = true;
    },
    create() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let ifHasEmptyGoods = false;
          let ifHasEmptyAccounts = false;

          // this.sales_account_items.map((item) => {
          //   if (!item.account || item.collection_amount === "" || item.collection_amount === null) {
          //     ifHasEmptyAccounts = true;
          //   }
          // });
          // if (ifHasEmptyAccounts) {
          //   this.$message.warn("请将结算账户信息填写完整");
          //   return false;
          // }

          if (this.materialItems.length == 0) {
            this.$message.warn("未添加产品");
            return false;
          }
          this.materialItems.map((item) => {
            if (item.sales_price === null || !item.sales_quantity) {
              ifHasEmptyGoods = true;
            }
          });
          if (ifHasEmptyGoods) {
            this.$message.warn("销售单价和销售数量必填");
            return false;
          }

          const sales_account_items = [];
          if (this.enableFinanceManage) {
            if (!this.sales_account_item.account) {
              this.$message.warn("请选择结算账户");
              return;
            }

            if (this.sales_account_item.account && this.sales_account_item.collection_amount > 0) {
              sales_account_items.push(this.sales_account_item);
            }
          }

          this.loading = true;
          let formData = {
            ...this.form,
            sales_account_items: [this.sales_account_item],
            sales_goods_items: this.materialItems.map((item) => {
              return {
                goods: item.goods,
                sales_quantity: item.sales_quantity,
                sales_price: item.sales_price,
              };
            }),
          };
          saleOrderCreate(formData)
            .then((data) => {
              this.$message.success("创建成功");
              this.$router.push({ path: "/sale/sale_record" });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    resetForm() {
      this.form = { other_amount: 0 };
      this.sales_account_item = { collection_amount: 0, account: this.defaultAccount };
      getSaleOrderNumber().then((data) => {
        this.form = { ...this.form, number: data.number, discount: 100 };
      });
      this.materialItems = [];
      this.handelAddAcount();
    },
    changeClient(value) {
      const index = this.clientsItems.findIndex((item) => item.id === value);
      if (index >= 0) {
        this.clientLevel = this.clientsItems[index]["level"];
      }
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
